// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

// Variables
@import 'variables-violet';

// Bootstrap
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "~startbootstrap-sb-admin-2/scss/sb-admin-2.scss";
@import "~dropzone/dist/dropzone.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
@import "~datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css";
@import "~datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css";
@import "~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css";
@import "~datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css";
@import "~datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css";
@import "~datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
@import "~datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css";
@import "~datatables.net-searchbuilder-bs4/css/searchBuilder.bootstrap4.min.css";
@import "~datatables.net-searchpanes-bs4/css/searchPanes.bootstrap4.min.css";
@import "~datatables.net-select-bs4/css/select.bootstrap4.min.css";
@import "custom.scss";
@import "responsive.css";
@import "~slim-select";
@import "~@fancyapps/fancybox/src/css/core.css";
// No poner código css, para eso usar el archivo custom.css
