/* slim select */

.ss-main .ss-single-selected{
    height: calc(1.6em + 0.75rem + 2px)!important;
    padding: 0.375rem 0.75rem!important;
}
.ss-content{
    width: calc(100% - 10px) !important;
}
/* customer show */
/* paymentTypes css */
span.badge {
    text-transform: capitalize;
}

a{
    color: $secondary;

    &:hover{
        color: $secondary-hover;
    }
}

.blur {
    filter: blur(2px);
}

.sidebar{
    background-color: $sidebar;

    .sidebar-heading {
        background: $sidebar-dark;
        color: rgba(255, 255, 255, 0.8) !important;
        padding: 4px 0;
    }
}

.bg-login-image {
    background: url("/logo-grande-njl.jpg") !important;
    background-position: center center !important;
    background-size: 80% !important;
    background-repeat: no-repeat !important;
}

.bg-register-image {
    background: url("/logo-grande-njl.jpg") !important;
    background-position: center center !important;
    background-size: 80% !important;
    background-repeat: no-repeat !important;
}

.bg-password-image {
    background: url("/logo-grande-njl.jpg") !important;
    background-position: center center !important;
    background-size: 80% !important;
    background-repeat: no-repeat !important;
}

table.table {
    width: 100% !important;
}

td.actions {
    &:not(.dataTables_empty) {
        text-align: end;
        width: 10px;
    }

    a {
        margin: 2px;
    }

    button {
        margin: 2px;
    }
}

.tab-content {
    table {
        width: 100% !important;
    }
}

.nav-tabs {
    border-bottom: 0 !important;
}

div.dataTables_wrapper {
    div.dataTables_filter {
        display: inline;
        float: right;
    }
}

#customerInfo {
    ul {
        list-style: none;
        padding-inline-start: 0;
    }
}

#balance {
    h2 {
        font-size: 1.4rem;
    }
}

.badge-efectivo {
    color: #fff;
    background-color: #5a5c69;
}

.badge-cheque {
    color: #fff;
    background-color: $success;
}

.badge-transferencia {
    color: #fff;
    background-color: $danger;
}

.badge-en-espera {
    background: $danger;
    color: #ffffff;
}

.badge-abierta {
    background: $danger;
    color: #ffffff;
}

.badge-en-proceso {
    background: $primary;
    color: #ffffff;
}

.badge-finalizado {
    background: $success;
    color: #ffffff;
}

.badge-cerrada {
    background: $success;
    color: #ffffff;
}

:disabled {
    cursor: not-allowed;
}

.nav-item {
    font-size: 14px;

    .icon {
        color: rgba(255, 255, 255, 0.6);
        font-size: 20px !important;
    }

    .nav-link{
        padding: .5rem!important;
    }
}

.nav-item.active {
    .icon, i {
        color: $sidebar!important;
    }

    a{
        color: $sidebar!important;
    }

    background: #f8f9fc;
}

span#orderNumber {
    color: $danger;
    font-size: 1.5em;
    font-weight: bold;
    vertical-align: middle;
}

form {
    label {
        width: 100%;

        .required {
            float: right;
            font-size: 9px;
            margin-top: 8px;
            text-transform: uppercase;
        }
    }
}

.table {
    .dropdown {
        text-align: center;
    }
}

.notifications{
    position: absolute; top: 50px; right: 30px; width: 50%; z-index: 99;
}

@media (max-width: 768px){
    .notifications{
        position: absolute; top: 50px; right: 5%; width: 90%; z-index: 99;
    }
}

@media (min-width: 768px) {
    #accordionSidebar {
        width: 8rem !important;
    }
    .sidebar.toggled {
        .nav-item {
            .nav-link {
                width: 100% !important;

                span {
                    &:not(.icon) {
                        font-size: .90rem !important;
                    }
                }
            }
        }
    }
}
