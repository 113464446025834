/*table tbody tr td:not(.dataTables_empty):last-child a, table tbody tr td:not(.dataTables_empty):last-child button {
    margin: 2px;
    display: block;
    width: 100%;
}*/

@media screen and (max-width: 545px){
    .btn.btn-md-block{
        display: block;
        width: 100%;
    }
}
